import dietro from "./compont/css/style.module.css";
import logo from "./compont/css/img/App_Icon.png";
import Bottone from "./compont/bottonedrop.js";
import Navbar from "./compont/nav.js";
import { useQuery, gql } from "@apollo/client";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Context } from "./Context.js";
import load from "./compont/css/img/load.gif";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import Categoryfilter from "./compont/categoryfilter";
import { Link } from "react-router-dom";
import Indietro from "./compont/css/img/freccia.png";
function ListaCategorie({ Lista }) {
  const [filter, setFilter] = useContext(Context);

  const Art = gql`
    query Lista_categorie {
      posts(first: 200) {
        edges {
          node {
            id
            artista {
              divisore
              divisoreEn
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(Art);
  if (loading)
    return (
      <div className={dietro.Coloreload}>
        {" "}
        <p className="text-center fixed text-3xl w-full h-full "> Loading</p>
        <div className="flex w-full justify-center items-center">
          <img
            className="flex center pt-10 justify-center w-11 items-center"
            src={load}
          ></img>
        </div>
      </div>
    );

  if (error)
    return (
      <div className={dietro.colore}>
        <p className="text-center text-3xl w-full"> Error </p>{" "}
      </div>
    );

  if (data) Lista(data);

  const flushed = data.posts.edges.map((i) => {
    return i18n.resolvedLanguage=="it"?i.node.artista.divisore:i.node.artista.divisoreEn;
    //return i.node.artista;
  });

  function RemoveDuplicate(data) {
    return [...new Set(data)];
  }

  return RemoveDuplicate(flushed).map(
    (
      node //Rimuove i duplicati ed esegue la composizione della pagina
    ) => (
      <Bottone
        titolo={node}
        percorso={"/artisti/" + node}
      />
    )
  );
}

function Lista({ t }) {
  const [lista, setLista] = useState([]);
  const [filter, setFilter] = useState({
    strumento: "all",
    genere: "all",
    categoria: "all",
  });
  return (
    <Context.Provider
      value={[filter, setFilter]}
      
    >
      <div className={dietro.categorylist}>
        <Link
          class="transition duration-500 ease-in-out transform md:hover:opacity-40 pt-1"
          to="/"
        >
          <img class="w-14 pt-4 pl-2" src={Indietro}></img>
        </Link>
        <div className={dietro.listbutton}>
            <ListaCategorie Lista={(x) => setLista(x)} />
        </div>
      </div>
    </Context.Provider>
  );
}
export default withTranslation()(Lista);
