import dietro from "./css/style.module.css";
import "./css/dropdown.css";
import logo from "./css/img/logo_icon.png";
import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";
import { useRef, useContext, useState } from "react";
import { Context } from "../Context.js";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

function RemoveDuplicate(data) {
    return [...new Set(data)];
}

function CategoryFilter(){
    //var option_list = [];
    //option_list = option.posts.edges.map((x) => x.node.artista.categoria);
    return (
        <div>
            <h1>Pluto</h1> 
        </div>
    );
}


export default withTranslation()(CategoryFilter);