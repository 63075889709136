import React from 'react';
import dietro from "./css/style.module.css";
import {Link} from "react-router-dom";
const divisore_desc = (diretive) =>{
  if(diretive.titolo.search("_")){
    const arr = diretive.titolo.split("_");
    return(
      <div class="flex justify-center Monteserrat-ExtraLight">
        <Link to={diretive.percorso} className={dietro.bottone} id={diretive.id} style={{width:'18rem',height:"7rem"}}>
          <p>
            {arr[0]}
            <br></br>
            <p>{arr[1]} {arr[2]}</p>
          </p>
        </Link>
      </div>
    );
  }else{
    return(
    <Link to={diretive.percorso} className={dietro.bottone}id={diretive.id}>
          
           {diretive.titolo} 
        </Link>
    )
  }

}
export default function Bottone(diretive){
    return(

    <div class="flex flex-col  pb-4 Monteserrat-ExtraLight">
        {/*  <Link to={diretive.percorso} className={dietro.bottone}id={diretive.id}>
          
           {diretive.titolo} 
        </Link>*/}
        {divisore_desc(diretive)}

        
        <Link to={diretive.percorso} class="text-center  Monteserrat-ExtraLight"><p> {diretive.descrizione}</p></Link>
        <Link to={diretive.percorso} class="text-center Monteserrat-ExtraLight  " ><p>{diretive.descrizione2}</p></Link>
    </div>
    

    );

}
