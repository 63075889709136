import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import dietro from "./css/style.module.css";
import Linksocial from "./linksocial.js";
import load from "./css/img/load.gif";
import i18n from "i18next";
import axios from "axios";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
export default function Artista() {
  const [slider1, Setslider1] = useState([]);
  const [slider2, Setslider2] = useState([]);
  const [slider3, Setslider3] = useState([]);
  const [slider4, Setslider4] = useState([]);
  const Collega = gql`
    query get_InfoArtista($id: ID!) {
      post(id: $id) {
        artista {
          nome
          instruments
          instrumentsEn
          twitter
          projectsEn
          play1
          play1Check
          play2
          play2Check
          play4Check
          play4
          play3Check
          play3
          cognome
          biografia
          biografiaEn
          amazonmusic
          applemusic
          artistWebsite
          deezer
          facebook
          galleria
          genre
          instagram
          linkedin
          soundcloud
          spotify
          vimeo
          youtube
          linkname
        }
      }
    }
  `;
  let { id } = useParams();
  const { loading, error, data } = useQuery(Collega, { variables: { id } });

  useEffect(() => {
    if (data) imagesimporter(data.post.artista.galleria);
  }, data);

  const imagesimporter = (folder) => {
    console.log(folder);
    axios
      .get("https://www.calma.management/getimage.php?name=" + folder)
      .then((res) => {
        res.data.slider1.map((x) => {
          if (x[0] != ".")
            Setslider1((slider1) => [
              ...slider1,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider1/" +
                  x,
              },
            ]);
        });
        res.data.slider2.map((x) => {
          if (x[0] != ".")
            Setslider2((slider2) => [
              ...slider2,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider2/" +
                  x,
              },
            ]);
        });
        res.data.slider3.map((x) => {
          if (x[0] != ".")
            Setslider3((slider3) => [
              ...slider3,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider3/" +
                  x,
              },
            ]);
        });
        res.data.slider4.map((x) => {
          if (x[0] != ".")
            Setslider4((slider4) => [
              ...slider4,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider4/" +
                  x,
              },
            ]);
        });
        console.log(res);
      });
  };

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const carouselData = (x) => {x.map(row => {
    return {
      key: String(row.id),
      children: <div
        style={{
          backgroundImage: `url(${row.image})`,
        }}
      />
    };
  })}

  if (loading)
    return (
      <div className={dietro.Coloreload}>
        {" "}
        <p className="text-center pt-60 fixed text-3xl w-full h-full ">
          {" "}
          Loading
        </p>
        <div className="flex w-full pt-10 justify-center items-center">
          <img
            className="flex center pt-60 justify-center w-11 items-center"
            src={load}
          ></img>
        </div>
      </div>
    );
  if (error) return <p> error </p>;
  return (
    <div className={dietro.Colore}>
      <Link class="   h-4 pt-1" to="/list">
        <img
          class="transition duration-500 ease-in-out transform hover:opacity-40 w-14 fixed pt-4 pl-2 "
          src={Indietro}
        ></img>
      </Link>
      <div class="flex md:flex-row flex-col  gap-x-3  w-full justify-between">
        <div class="flex flex-col md:pl-24 pt-24 h-full text-center md:text-left  md:w-1/3 ">
          <p class="Monteserrat-Light font-semibold text-2xl text-black opacity-80">
            {" "}
            {data.post.artista.nome} {data.post.artista.cognome}
          </p>
          <p class="Monteserrat font-light pl-1.5 pt-1  ">
            {i18n.resolvedLanguage == "it"
              ? data.post.artista.instruments
              : data.post.artista.instrumentsEn}
          </p>
        </div>
        <div class="md:w-1/3"></div>
        <div class="flex  md:w-1/3 w-full md:h-32 py-5 px-4 md:m-4">
          <Linksocial
            facebook={data.post.artista.facebook}
            instagram={data.post.artista.instagram}
            youtube={data.post.artista.youtube}
            apple={data.post.artista.applemusic}
            amazon={data.post.artista.amazonmusic}
            spotify={data.post.artista.spotify}
            vimeo={data.post.artista.vimeo}
            linkdin={data.post.artista.linkedin}
            soundcloud={data.post.artista.soundcloud}
            deezer={data.post.artista.deezer}
          />
        </div>
      </div>
      <div class="flex md:flex-row flex-col w-full h-full gap-x-3 md:pt-14 justify-between">
        <div class="md:w-1/3 m-2">
          {slider1.length != 0 && (
            <div className="m-4"><ImageGallery className="p-2" items={slider1} showBullets={true} showPlayButton={false}/></div>
          )}
          {slider2.length != 0 && (
            <div className="m-4"><ImageGallery className="p-2" items={slider2} showBullets={true} showPlayButton={false}/></div>
          )}
          {slider3.length != 0 && (
            <div className="m-4"><ImageGallery className="p-2" items={slider3} showBullets={true} showPlayButton={false}/></div>
          )}
          {slider4.length != 0 && (
            <div className="m-4"><ImageGallery className="p-2" items={slider4} showBullets={true} showPlayButton={false}/></div>
          )}
        </div>
        <div class="md:w-1/3 m-4">
          <p className={dietro.testoArtista}>
            <div
              class="text-justify"
              dangerouslySetInnerHTML={
                i18n.resolvedLanguage == "it"
                  ? { __html: data.post.artista.biografia }
                  : { __html: data.post.artista.biografiaEn }
              }
            />
            <br />
          </p>

          {data.post.artista.projectsEn != null && (
            <p class=" flex w-full flex-col Monteserrat md:pt-14 pt-7 gap-4">
              <b className="text-xl text-center Monteserrat ">Projects</b>
              <a
                className={dietro.testoArtista}
                dangerouslySetInnerHTML={{
                  __html: data.post.artista.projectsEn,
                }}
              />
            </p>
          )}

          {data.post.artista.artistWebsite != null && (
            <div class="flex w-full items-end   pt-6 justify-center">
              <a href={data.post.artista.artistWebsite}>
                <div className={dietro.single_button}>{ data.post.artista.linkname == "" ? data.post.artista.linkname: "website" }</div>
              </a>
            </div>
          )}
        </div>
        <div class="flex flex-col md:w-1/3 w-full h-full items-center justify-center gap-y-1.5 md:m-4">
          {data.post.artista.play1Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12  "
              height="265"
              allowfullscreen
              src={"https://www.youtube.com/embed/" + data.post.artista.play1}
              frameborder="0"
            ></iframe>
          )}
          {data.post.artista.play1Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.artista.play1
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}
            {data.post.artista.play1Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.artista.play1
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}
          {data.post.artista.play2Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12 "
              height="265"
              src={"https://www.youtube.com/embed/" + data.post.artista.play2}
            ></iframe>
          )}
       
          
          {data.post.artista.play2Check == "spotify"  && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.artista.play2
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}
            {data.post.artista.play2Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.artista.play2
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}

          {data.post.artista.play3Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12  "
              height="265"
              src={"https://www.youtube.com/embed/" + data.post.artista.play3}
            ></iframe>
          )}


          
          {data.post.artista.play3Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.artista.play3
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}
            {data.post.artista.play3Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.artista.play3
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}

          {data.post.artista.play4Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12   "
              height="265"
              src={"https://www.youtube.com/embed/" + data.post.artista.play4}
            ></iframe>
          )}
          {data.post.artista.play4Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.artista.play4
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
          {data.post.artista.play4Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.artista.play4
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
            )}
        </div>
      </div>
    </div>
  );
}
